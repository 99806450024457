import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useCompanyById({ companyId, ...props }) {
  const options = props?.options || {}
  const queryKey = ['my-user-company-data', companyId]
  const query = useQuery(queryKey, () => ApiCall.Companies.getById(companyId), {
    ...options,
    enabled: options.enabled && !!companyId,
  })

  return {
    ...query,
    data: query?.data?.data,
  }
}

export default useCompanyById
