import Cookies from 'js-cookie'
import moment from 'moment'

export const encodeBase64 = (data) => {
  return Buffer.from(data).toString('base64')
}
export const decodeBase64 = (data) => {
  return Buffer.from(data, 'base64').toString('latin1')
}

export const getLocalStorageKey = () => encodeBase64('userInformations')

export const saveToLocalStorage = (values) => {
  Cookies.set('refreshToken', values?.refreshToken, 60)

  localStorage.setItem(
    encodeBase64(getLocalStorageKey()),
    encodeBase64(JSON.stringify(values)),
  )
}

export const addZero = (number) => (number < 10 ? `0${number}` : number)

export const formatDate = (dateValue) => {
  const date = new Date(dateValue)

  return `${date.getFullYear()} - ${addZero(date.getMonth() + 1)} - ${addZero(
    date.getDate(),
  )}`
}

export const getFromLocalStorage = () => {
  try {
    const rawData = localStorage.getItem(encodeBase64(getLocalStorageKey()))
    const decodedData = rawData ? decodeBase64(rawData) : '{}'
    return JSON.parse(decodedData)
  } catch (error) {}
}

export const formatTimeLogData = (data) => {
  const _date = moment(data?.start_time)
  return {
    ...data,
    date: _date?.format('DD-MM-YYYY') || null,
    times: _date?.format('hh:mm') || null,
  }
}

export const formatBEDate = (date) => {
  return new Date(moment(date).utc()).toISOString()
}
export function getWBSOYearForSeo() {
  const currentDate = new Date()
  let year = currentDate.getFullYear()
  if (
    currentDate.getMonth() > 8 ||
    (currentDate.getMonth() === 8 && currentDate.getDate() > 15)
  ) {
    year += 1
  }
  return year
}
