import { Button } from 'antd'

const ConfirmationButton = ({ children, className = '', ...props }) => {
  return (
    <Button
      className={`bg-gray border-[1px] border-[#0d393a] rounded-lg ${className}`}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ConfirmationButton
