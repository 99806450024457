import { Col, Row, Typography } from 'antd'

export const WelcomeScreen = () => {
  return (
    <Row className="max-w-xl mx-auto py-12" gutter={[16, 24]}>
      <Col span={24}>
        <Typography variant="h3" component="h3">
          🌟 Embark on a Smoother WBSO Journey!
        </Typography>
      </Col>
      <Col span={24}>
        <Typography variant="body1">
          At Traqqie, we're dedicated to making your WBSO hours tracking as
          seamless as possible. Let's get your account set up with a few
          straightforward steps.
          <br />
          <br />
          <b>Your Onboarding Roadmap:</b>
          <br />
          <br />
          <ol style={{ paddingLeft: 0 }}>
            <li>
              <b>Connect Your Issue Management System:</b> Link Traqqie with
              your existing issue management tool. This integration is key to
              tracking your project activities efficiently.
            </li>
            <br />
            <li>
              <b>Sync Your Calendar:</b> Integrate your calendar with Traqqie to
              align your schedule with your WBSO tracking. This step ensures all
              your time is accounted for accurately.
            </li>
            <br />
            <li>
              <b>Additional Information for Team Leads:</b> If you're the first
              member from your team to join Traqqie, we'll need a bit more
              information to set up your organization's profile. This one-time
              setup paves the way for smoother team onboarding in the future.
            </li>
            <br />
          </ol>
        </Typography>
      </Col>
    </Row>
  )
}
