import { useAuth, VerifyingLoading } from 'components'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

const PageLayout = (props) => {
  const router = useRouter()
  const { isVerifying, authUser } = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  const returnValue = (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  )

  let isPublicPage = true
  let subRoute = false

  // useEffect(() => {
  //   if (router.isReady) {
  //     const { token, refreshToken } = getFromLocalStorage()
  //     setIsLoading(false)

  //     subRoute = `/${router.pathname.split('/')[1]}`

  //     isPublicPage =
  //       PUBLIC_PAGES.includes(router.pathname) ||
  //       PUBLIC_PAGES.includes(subRoute)

  //     if (!authUser && !isVerifying && !isPublicPage) {
  //       router.replace(PAGE.signin)
  //     }
  //   }
  // }, [router.pathname, router.isReady])

  if (isPublicPage) {
    return returnValue
  }
  if (!isPublicPage && (isVerifying || isLoading)) {
    return <VerifyingLoading />
  }

  return returnValue
}

export default PageLayout
