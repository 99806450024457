// components/auth.js
import { useEffect, useState } from 'react'

import Cookies from 'js-cookie'

export default function StagingAuth({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [password, setPassword] = useState('')

  // Check if the user is already authenticated via cookie on component mount
  useEffect(() => {
    const stagingAuthCookie = Cookies.get('staging-auth')
    if (stagingAuthCookie === 'authenticated') {
      setIsAuthenticated(true)
    }
  }, [])

  const checkPassword = () => {
    if (password === 'funkydoggy') {
      setIsAuthenticated(true)
      // Set a cookie for 24 hours
      Cookies.set('staging-auth', 'authenticated', { expires: 1 })
    } else {
      alert('Wrong password')
    }
  }

  if (isAuthenticated) {
    return <>{children}</>
  }

  return (
    <div>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={checkPassword}>Enter</button>
    </div>
  )
}
