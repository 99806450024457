import React from 'react'

import SyncOutlined from '@ant-design/icons/SyncOutlined'
import { Space, Spin } from 'antd'

const Loading = React.forwardRef((props, ref) => {
  const antIcon = <SyncOutlined style={{ fontSize: 36 }} spin />

  return (
    <div
      ref={ref}
      className="w-screen h-screen flex flex-col align-middle justify-center bg-white"
    >
      <Space direction="vertical" align="center">
        <Spin indicator={antIcon} />
        <p>Verifying...</p>
      </Space>
    </div>
  )
})

export default Loading
