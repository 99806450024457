import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useProjectLabelsAvailable(props) {
  const options = props?.options || {}

  const queryKey = ['project-labels']
  const query = useQuery(queryKey, () => ApiCall.Users.getProjectLabels(), {
    ...options,
  })

  return {
    ...query,
    // TEMPORARY:
    data: query.data?.data,
  }
}

export default useProjectLabelsAvailable
