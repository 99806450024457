import { Button } from 'antd'

export const extractDomain = (email) => {
  const atIndex = email.indexOf('@')
  if (atIndex !== -1) {
    return email.substring(atIndex + 1)
  } else {
    return 'Invalid email address'
  }
}
export const ScheduleCall = ({ onCompleted, onScheduled }) => {
  // Placeholder for the actual scheduling functionality
  return (
    <div style={{ padding: '24px', textAlign: 'center' }}>
      <Button type="primary" onClick={onScheduled}>
        Schedule a Call
      </Button>
    </div>
  )
}
