import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useUserTimeLog(props) {
  const options = props?.options || {}
  const startDate = props?.start.format('YYYY-MM-DD') || null
  const endDate = props?.end.format('YYYY-MM-DD') || null
  const is_completed =
    props?.is_completed !== undefined ? props.is_completed : null

  const queryKey = ['user-time-log', startDate, endDate, is_completed]
  const query = useQuery(
    queryKey,
    () => ApiCall.TimeLog.getList(startDate, endDate, is_completed),
    {
      ...options,
    },
  )

  return {
    ...query,
    // TEMPORARY:
    data: query.data?.data,
  }
}

export default useUserTimeLog
