import Fetcher from './Fetcher'

class BaseApiCall {
  constructor() {
    this.api = Fetcher.createAuthAxios(process.env.BACKEND_API)
  }

  Companies = {
    create: (
      data = {
        name: '',
        domain: '',
        website: '',
        kvk_number: '',
        products: [],
      },
    ) => {
      return this.api.post('/companies/', {
        ...data,
      })
    },
    createWithToken: (
      token,
      data = {
        name: '',
        domain: '',
        website: '',
        kvk_number: '',
        description: '',
      },
    ) => {
      return axios.post(
        `${process.env.NEXT_PUBLIC_BACKEND_API}/companies/`,
        {
          ...data,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
    },
    update: (uuid, data) => {
      return this.api.patch(`/companies/${uuid}/`, {
        ...data,
      })
    },
    getById: (uuid) => {
      return this.api.get(`/companies/${uuid}/`)
    },
  }

  Jira = {
    authorize: () => {
      return this.api.get(`/jira/authorize/`)
    },
    callback: () => {
      return this.api.get(`/jira/auth-callback/`)
    },
  }
  Linear = {
    authorize: () => {
      return this.api.get(`/linear/authorize/`)
    },
    callback: () => {
      return this.api.get(`/linear/auth-callback/`)
    },
  }
  Asana = {
    authorize: () => {
      return this.api.get(`/asana/authorize/`)
    },
    callback: () => {
      return this.api.get(`/asana/auth-callback/`)
    },
  }

  GoogleCalendar = {
    authorize: () => {
      return this.api.get(`/google/authorize/`)
    },
    events: (params) => {
      const start_date = params.queryKey[1]
      const end_date = params.queryKey[2]
      if (start_date !== undefined && end_date != undefined) {
        return this.api.get(
          `/google/events/?start_date=${start_date}&end_date=${end_date}`,
        )
      }
      return this.api.get(`/google/events/`)
    },
  }

  Health = {
    cek: () => {
      return this.api.get(`/health/`)
    },
  }

  ArchivedIssue = {
    getList: () => {
      return this.api.get(`/archived_issues/`)
    },
    create: (unique_id_provider) => {
      return this.api.post(`/archived_issues/`, { unique_id_provider })
    },
    delete: (uuid) => {
      return this.api.delete(`/archived_issues/${uuid}/`)
    },
  }

  Projects = {
    getList: (company = null) => {
      const paramsObj = {}
      if (company !== null) {
        paramsObj.company = company
      }
      return this.api.get(`/projects`, { params: { ...paramsObj } })
    },
    getById: (uuid) => {
      return this.api.get(`/projects/${uuid}/`)
    },
    addTags: (uuid, tags) => {
      return this.api.post(`/projects/${uuid}/tags/`, tags)
    },
    create: (data) => {
      return this.api.post('/projects/', data)
    },
    update: (
      uuid,
      data = {
        name: '',
        description: '',
        totalApprovedHours: '',
      },
    ) => {
      return this.api.patch(`/projects/${uuid}/`, {
        ...data,
      })
    },
    delete: (uuid) => {
      return this.api.delete(`/projects/${uuid}/`)
    },
  }

  searchKvk = (query) => {
    return this.api.get(`/search-kvk/?query=${query}`)
  }

  TimeLog = {
    adminDownload: (props) => {
      if (props?.startDate !== undefined && props?.endDate !== undefined) {
        const startDate = props?.startDate.format('YYYY-MM-DD') || null
        const endDate = props?.endDate.format('YYYY-MM-DD') || null
        const queryParams = {
          start: startDate,
          end: endDate,
          all: 'True',
        }
        if (props?.company !== null) {
          queryParams.company = props?.company
        }
        return this.api.get(`/timelog/download`, {
          responseType: 'blob',
          params: queryParams,
        })
      }
    },
    leaderboard: (ref_date, period) => {
      return this.api.get(`/timelog/leaderboard`, {
        params: { ref_date, period },
      })
    },
    projectSummary: (props) => {
      return this.api.get(`/timelog/project_summary`)
    },
    getList: (startDate, endDate, is_completed = null) => {
      let params = {}

      if (startDate !== undefined && endDate !== undefined) {
        params.start = startDate
        params.end = endDate
      }

      if (is_completed !== null) {
        params.is_completed = is_completed
      }

      return this.api.get(`/timelog`, { params })
    },
    getListAll: (startDate, endDate, company = null) => {
      const paramsObj = {}

      if (company !== null) {
        paramsObj.company = company
      }
      if (startDate !== undefined && endDate !== undefined) {
        return this.api.get(`/timelog`, {
          params: { ...paramsObj, all: 'True', start: startDate, end: endDate },
        })
      }
      return this.api.get(`/timelog`, {
        params: { ...paramsObj, all: 'True' },
      })
    },
    getById: (uuid) => {
      return this.api.get(`/timelog/${uuid}/`)
    },
    create: (
      data = {
        name: '',
        start_time: '',
        end_time: '',
        project: null,
      },
    ) => {
      return this.api.post('/timelog/', {
        ...data,
      })
    },
    createMany: (data) => {
      const dataArr = data.map((d) => {
        return {
          name: d.title,
          start_time: d.start,
          end_time: d.end,
          project: d.project
            ? typeof d.project === 'object'
              ? project.value
              : d.project
            : null,
          type: d.is_calendar_event ? 'CALENDAR' : 'ISSUE',
          description: d.description,
          description_type: d.description_type,
          issue_url: d.issue_url,
        }
      })

      return this.api.post('/timelog/', dataArr)
    },
    write: (
      uuid,
      data = {
        name: '',
        start_time: '',
        end_time: '',
        type: '',
      },
    ) => {
      return this.api.patch(`/timelog/${uuid}/`, {
        ...data,
      })
    },
    delete: (uuid) => {
      return this.api.delete(`/timelog/${uuid}/`)
    },
    changeProject: (uuid, project) => {
      return this.api.patch(`/timelog/${uuid}/`, { project })
    },
    acknowledgeFeedback: (timelogUuid, feedbackUuid) => {
      return this.api.post(
        `/timelog/${timelogUuid}/${feedbackUuid}/acknowledge/`,
      )
    },
  }

  Advisors = {
    create: (nameVal, domain) => {
      return this.api.post('/advisors/', {
        name: nameVal,
        domain: domain,
      })
    },
    mandates: () => {
      return this.api.get('/advisors/mandates/')
    },
    getList: () => {
      return this.api.get('/advisors/')
    },
    grantAccess: (uuid, data) => {
      return this.api.post(`/advisors/${uuid}/grant_access/`, data)
    },
    revokeAccess: (uuid) => {
      return this.api.post(`/advisors/${uuid}/revoke_access/`)
    },
  }

  Users = {
    me: () => {
      return this.api.get(`/users/me/`)
    },
    stats: () => {
      return this.api.get(`/users/stats/`)
    },
    getList: () => {
      return this.api.get(`/users/`)
    },
    getRelevantIssues: (start_date, end_date) => {
      if (start_date == undefined || end_date == undefined) {
        return this.api.get(`/users/relevant_issues/`)
      }

      return this.api.get(
        `/users/relevant_issues/?start_date=${start_date}&end_date=${end_date}`,
      )
    },
    getPlannedIssues: (start_date, end_date) => {
      return this.api.get(
        `/users/planner/?start_date=${start_date}&end_date=${end_date}`,
      )
    },
    getProjectLabels: () => {
      return this.api.get(`/users/issue_management_labels/`)
    },
    getById: (uuid) => {
      return this.api.get(`/users/${uuid}/`)
    },
    updateRole: (uuid, role) => {
      return this.api.patch(`/users/${uuid}/update_role/`, {
        is_client_admin: role,
      })
    },
  }
  WaitList = {
    create: (data) => {
      return this.api.post('/waitlist/', data)
    },
    demoSignUp: (data) => {
      return this.api.post('/demo-registration/', data)
    },
  }

  payments = {
    createCheckoutSession: ({ quantity, price_id, mode, application_uuid }) => {
      return this.api.post(`/payments/checkout/create/`, {
        application_uuid,
        quantity,
        price_id,
        mode,
      })
    },
  }

  wbso = {
    getPrices: () => {
      return this.api.get(`payments/priceinfo/`)
    },
    getApplications: () => {
      return this.api.get(`/wbso/applications/`)
    },
    getApplicationById: (id) => {
      return this.api.get(`/wbso/applications/${id}/`)
    },
    deleteApplicationById: (id) => {
      return this.api.delete(`/wbso/applications/${id}/`)
    },
    createApplication: (companyId) => {
      return this.api.post(`/wbso/applications/`, { company: companyId })
    },
    getApplicationResponse: (applicationId) => {
      return this.api.get(`/wbso/applications/${applicationId}/responses/`)
    },
    getGeneratedApplication: (applicationId) => {
      return this.api.get(`/wbso/applications/${applicationId}/review/`)
    },
    getApplicationFeedback: (generatedApplicationId) => {
      return this.api.get(
        `/wbso/applications/feedback/${generatedApplicationId}/`,
      )
    },
    updateApplicationFeedback: (generatedApplicationId, key, feedback) => {
      return this.api.patch(
        `/wbso/applications/feedback/${generatedApplicationId}/`,
        { key, application_generated: generatedApplicationId, feedback },
      )
    },
    createApplicationFeedback: (generatedApplicationId, key, feedback) => {
      return this.api.post(
        `/wbso/applications/feedback/${generatedApplicationId}/`,
        { key, application_generated: generatedApplicationId, feedback },
      )
    },
    saveApplicationResponse: (
      applicationId,
      data = [
        {
          application: undefined,
          question: undefined,
          response: undefined,
        },
      ],
    ) => {
      return this.api.post(
        `/wbso/applications/${applicationId}/responses/`,
        data,
      )
    },
    getApplicationResponseById: (applicationId, responseId) => {
      return this.api.get(
        `/wbso/applications/${applicationId}/responses/${responseId}/`,
      )
    },
    updateApplicationResponse: (
      applicationId,
      responseId,
      data = {
        application: undefined,
        question: undefined,
        response: undefined,
      },
    ) => {
      return this.api.patch(
        `/wbso/applications/${applicationId}/responses/${responseId}/`,
        data,
      )
    },
    getFeedback: (reponseId) => {
      return this.api.get(`/wbso/responses/${reponseId}/feedback/`)
    },
    getQuestions: (applicationId) => {
      return this.api.get(
        `/wbso/questions/${applicationId ? `?application=${applicationId}` : ''}`,
      )
    },
    uploadFile: (applicationId, file) => {
      const formData = new FormData()
      formData.append('file', file)

      return this.api.post(
        `/wbso/applications/${applicationId}/upload/`,
        formData,
      )
    },
  }
}

const ApiCall = new BaseApiCall()

export default ApiCall
