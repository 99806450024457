import { Modal } from 'antd'

const StyledModal = ({ children, footer, ...props }) => {
  return (
    <Modal
      {...props}
      style={{
        borderRadius: '12px',
        overflow: 'hidden',
        ...props.style, // Allow custom styles to be merged
      }}
      bodyStyle={{
        padding: '24px',
        borderRadius: '12px',
        backgroundColor: '#f9f9f9',
        maxHeight: '70vh', // Limits the height for scrollable content
        overflowY: 'auto', // Enables vertical scrolling if content exceeds maxHeight
        ...props.bodyStyle,
      }}
      footer={null}
    >
      {children}
      {footer && (
        <div
          style={{
            padding: '12px 24px',
            borderTop: '1px solid #e0e0e0',
            backgroundColor: '#f9f9f9', // Matches body background color
            display: 'flex',
            justifyContent: 'flex-end',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        >
          {footer}
        </div>
      )}
    </Modal>
  )
}

export default StyledModal
