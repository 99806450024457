import { UserOutlined } from '@ant-design/icons'
import {
  Alert,
  Avatar,
  Col,
  Divider,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from 'antd'
import Sider from 'antd/lib/layout/Sider'
import cn from 'classnames'
import { ModalOnboarding, useAuth } from 'components'
import {
  ReactJoyrideNoSSR,
  useProductTour,
} from 'components/contexts/ProductTourContext'
import { useMyUser } from 'data'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { ADMIN_MENUS, MENUS } from 'utils'
import { PAGE } from 'utils/pages'

const filterMenuForUserProducts = (item, authUser) => {
  if (item.adminproduct && item.application) {
    return true
  }
  if (item.adminproduct && authUser?.has_access_admin) {
    return true
  }
  if (item.application && authUser?.has_access_application) {
    return true
  }
  // Show common items that don't depend on specific product access
  return false
}

const LayoutLoginArea = ({ pageTitle, breadcrumbItems, children }) => {
  const router = useRouter()
  const { signOut, authUser, isLoading: authUserLoading } = useAuth()

  const {
    productTourSteps,
    updateProductTourForPage,
    callbackProductTour,
    showProductTour,
    setShowProductTour,
  } = useProductTour()
  const isApplicationRoute = router.pathname === PAGE.wbsoAppDashboard

  const [collapse, setCollapse] = useState(true)
  const [showAlertBanner, setShowAlertBanner] = useState(
    !isApplicationRoute &&
      !authUser?.has_completed_onboarding &&
      authUser?.has_access_admin,
  )
  // const isAdvisor = authUser?.is_advisor || false
  const isAdmin = authUser?.is_client_admin || false
  const hasAccessOneClick = authUser?.has_access_one_click || false

  const menuToShowOneClickFilter = hasAccessOneClick
    ? MENUS.filter((d) => d.key !== PAGE.calendarView)
    : MENUS.filter((d) => d.key !== PAGE.timeRegistration)

  // const showAlertBanner =
  //   !isApplicationRoute &&
  //   !authUser?.has_completed_onboarding &&
  //   authUser?.has_access_admin

  const menuToShowBeforeFinal = menuToShowOneClickFilter.filter((item) => {
    return filterMenuForUserProducts(item, authUser)
  })

  const filteredAdminMenu = {
    ...ADMIN_MENUS,
    children: ADMIN_MENUS.children.filter((child) => {
      return filterMenuForUserProducts(child, authUser)
    }),
  }

  const menuToShow = isAdmin
    ? [
        ...menuToShowBeforeFinal.filter((item) => item.key !== 'logout'), // Filter out logout temporarily
        filteredAdminMenu, // Add the filtered admin menu for admins
        menuToShowBeforeFinal.find((item) => item.key === 'logout'), // Re-add the logout button
      ]
    : menuToShow

  // const menutToShowWithAdmin = ADMIN_MENUS.

  const { data: user, refetch, isLoading } = useMyUser()
  const hasCompletedOnboarding = authUser?.has_completed_onboarding || false
  const hasClosedModal = Cookies.get('closedModal') === 'true' ? true : false

  const [showOnboardingModal, setShowOnboarding] = useState(
    !hasCompletedOnboarding && user?.has_access_admin && !hasClosedModal,
  )

  const hasAccess = true

  useEffect(() => {
    // product tour definition
    if (router.pathname === PAGE.calendarView) {
      updateProductTourForPage('calendarView')
    }

    setShowOnboarding(
      !hasCompletedOnboarding && user?.has_access_admin && !hasClosedModal,
    )
    // refetch()
  }, [hasCompletedOnboarding, authUserLoading])

  useEffect(() => {
    setShowAlertBanner(
      !isApplicationRoute &&
        !authUser?.has_completed_onboarding &&
        authUser?.has_access_admin,
    )
  }, [authUser, isApplicationRoute])

  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      signOut()
    } else {
      router.push(key)
    }
  }

  const closeOnboardingModal = () => {
    setShowOnboarding(false)
    Cookies.set('closedModal', 'true', { expires: 0.5 }) // Set cookie for 30 minutes
  }

  const productTourCalendarViewCallbackFunction = (data) => {
    if (data?.status === 'finished') {
      handleProductTourCalendarViewFinish()
    }
  }

  const clickConinueOnboarding = () => {
    setShowOnboarding(true)
  }

  // product tour finish callback

  const handleProductTourCalendarViewFinish = () => {
    Cookies.set('productTourCalendarViewFinished', 'true', { expires: 365 * 2 })
    setShowProductTour(false)
  }

  if (authUserLoading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <ReactJoyrideNoSSR
        steps={productTourSteps}
        continuous={true}
        showProgress={true}
        showSkipButton={false}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        run={!showOnboardingModal && showProductTour}
        styles={{
          options: {
            size: 2,
            arrowColor: '#e3ffeb',
            textAlign: 'justify !important',
            // primaryColor: '#000',
            fontSize: '12px !important',
            textColor: '#004a14',
            width: 900,
            zIndex: 1000,
          },
          tooltipContent: {
            textAlign: 'justify',
            fontSize: 14,
          },
        }}
        callback={(data) => {
          // TODO: improve this: move to the context, when this grows.
          // for now its fine, but when we have more pages, this will be a mess

          if (router.pathname === PAGE.calendarView) {
            productTourCalendarViewCallbackFunction(data)
          }
        }}
      />

      <Layout hasSider className="my-layout">
        <Sider
          width={250}
          collapsible
          collapsed={collapse}
          onCollapse={(val) => setCollapse(val)}
          breakpoint="md"
          className="h-screen fixed"
          style={{ width: 300, maxWidth: 'unset' }}
        >
          <Typography className="text-white font-semibold text-lg p-4 mb-8">
            {/* <Link href="/" style={{ color: 'white' }}> */}
            Traqqie.com
            {/* </Link> */}
          </Typography>

          <Menu
            theme="dark"
            y
            mode="inline"
            // disabled={!hasCompletedOnboarding}
            selectedKeys={router.pathname}
            items={menuToShow}
            onClick={handleMenuClick}
          />
          <Space
            direction="horizontal"
            align="start"
            className={cn('w-full absolute bottom-16 left-0', {
              'justify-center': collapse,
              'pl-6': !collapse,
            })}
          >
            <Avatar
              src={user?.photoURL || ''}
              alt={user?.display_name || ''}
              icon={<UserOutlined />}
            />
            <Space direction="vertical" hidden={collapse} className="gap-1">
              <span className="text-gray-100">{user?.display_name || ''}</span>
              <span className="text-gray-400 text-sm font-light">
                {isAdmin ? 'Admin' : 'User'}
              </span>
            </Space>
          </Space>
        </Sider>
        <Layout
          className={cn(
            'site-layout min-h-screen py-6 px-3 sm:px-4 lg:px-8 transition-all ease-in-out duration-200 ',
            {
              'ml-60': !collapse,
              'ml-20': collapse,
            },
          )}
        >
          <Row>
            <Col span={24}>
              <Typography className="text-2xl">{pageTitle}</Typography>
              <Divider />
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <Breadcrumb separator="/">
                {['Home', ...breadcrumbItems]?.map((b) => (
                  <Breadcrumb.Item key={b}>{b}</Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </Col>
          </Row> */}

          <Row>
            {showAlertBanner && (
              <Alert
                style={{ width: '100%', borderRadius: '8px' }}
                message={
                  <span>
                    Your onboarding is incomplete. Traqqie Administration
                    requires the necessary access to function properly.{' '}
                    <a
                      onClick={clickConinueOnboarding}
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      Click here to finish setting it up.
                    </a>
                  </span>
                }
                banner
              />
            )}
            <Col span={24} className="py-8">
              {!hasAccess ? (
                <Row className="w-full mt-32" align="middle" justify="center">
                  <Col>
                    <Typography>
                      Upss.. You are not allowed to access this page!
                    </Typography>
                  </Col>
                </Row>
              ) : (
                <>{children}</>
              )}
            </Col>
          </Row>
        </Layout>
      </Layout>

      {authUser && authUser.has_access_admin ? (
        <>
          <ModalOnboarding
            open={showOnboardingModal}
            onClose={closeOnboardingModal}
            // open={true}
            onFinished={() => {
              closeOnboardingModal()
              location.replace(location.href)
            }}
            refetchUser={refetch}
            user={user}
          />
        </>
      ) : null}
    </>
  )
}

export default LayoutLoginArea
