// import 'antd/dist/antd.less'
import { FirebaseProvider, PageLayout } from 'components'
import StagingAuth from 'components/StagingAuth/StagingAuth'
import { AdvisorSelectedCompanyProvider } from 'components/contexts/AdvisorSelectedCompany'
import { ProductTourProvider } from 'components/contexts/ProductTourContext'
import { appWithTranslation } from 'next-i18next'
import Head from 'next/head'
import { Router } from 'next/router'
import NProgress from 'nprogress' //nprogress module
import { Fragment } from 'react'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/sass/styles.scss'
import 'styles/globals.css'
import { trackPageView } from 'utils/analytics'

const isBrowser = typeof window !== 'undefined'

if (isBrowser) {
  // For the first page load
  trackPageView()

  Router.events.on('routeChangeStart', () => NProgress.start())
  Router.events.on('routeChangeComplete', () => {
    trackPageView()
    NProgress.done()
  })
  Router.events.on('routeChangeError', () => NProgress.done())
}

function MyApp({ Component, pageProps }) {
  const isStaging = process.env.NEXT_PUBLIC_NODE_ENV === 'STAGING'

  return (
    <Fragment>
      <Head>
        <title>Traqqie</title>

        {isStaging && (
          <>
            {' '}
            <meta name="robots" content="noindex" />{' '}
            <meta
              name="google-site-verification"
              content="9wxhKmDO6hV6DIJSllRN-E-rhLQNWbKsOYROGAsxDtc"
            />
          </>
        )}
      </Head>

      <FirebaseProvider>
        <ProductTourProvider>
          <AdvisorSelectedCompanyProvider>
            {isStaging ? (
              <StagingAuth>
                <PageLayout>
                  <Component {...pageProps} />
                </PageLayout>
              </StagingAuth>
            ) : (
              <PageLayout>
                <Component {...pageProps} />
              </PageLayout>
            )}
          </AdvisorSelectedCompanyProvider>
        </ProductTourProvider>
      </FirebaseProvider>
    </Fragment>
  )
}

export default appWithTranslation(MyApp)
