import dynamic from 'next/dynamic'
import { createContext, useContext, useState } from 'react'

export const ProductTourContext = createContext()

const tourConfig = {
  calendarView: [
    {
      target: '.calendar-view',
      title: 'Welcome to Your Personal WBSO View!',
      content:
        "Discover the essentials of your WBSO journey right here on your personalized view! Let's embark on a guided tour to navigate through the key features and functionalities that will streamline your WBSO tracking experience.",
      placement: 'top-start',
      disableScrolling: true,
      disableBeacon: true,
    },
    {
      target: '.calendar-overview',
      content:
        'Meet your WBSO Calendar - the core of your weekly planning and tracking! Not only does it display your registered WBSO hours, but it also integrates events from your calendar, making tracking and planning a breeze. Stay organized and ahead with this central overview.',
      placement: 'top-start',
      disableScrolling: true,
      // disableBeacon: true,
    },
    {
      target: '.legend-block',
      content:
        "Here's the Legend Block, a key to understanding your calendar at a glance. Each event type on your calendar is color-coded for easy identification, simplifying the way you view and manage your various activities and tasks.",
      placement: 'top-start',
      disableScrolling: true,
      // disableBeacon: true,
    },
    {
      target: '.ticket-list',
      content:
        "In your Ticket List, you find all the tasks assigned to you or that you're working on. It's your call to decide which of these are WBSO. The quickest way to schedule them? Simply drag and drop them into your calendar. Organize your WBSO tickets efficiently and effectively.",
      placement: 'top-end',
      disableScrolling: true,
    },
    {
      target: '.rbc-current-time-indicator',
      content:
        'Did you know you can interact directly with the calendar? Click on any time slot to add tickets or activities. This feature makes scheduling your tasks straightforward and intuitive, enhancing your planning capabilities.',
    },
    {
      target: '.product-tour-icon',
      content: 'Need a refresher? Click here to restart the tour.',
    },
  ],
}

export const ProductTourProvider = ({ children }) => {
  const [productTourSteps, setProductTourSteps] = useState([])
  const [showProductTour, setShowProductTour] = useState(false)
  // const [callbackProductTour, setCallbackProductTour] = useState(() => {})

  const updateProductTourForPage = (page) => {
    // Update tour steps based on the page
    setProductTourSteps(tourConfig[page] || [])
  }

  return (
    <ProductTourContext.Provider
      value={{
        productTourSteps,
        showProductTour,
        updateProductTourForPage,
        setShowProductTour,
      }}
    >
      {children}
    </ProductTourContext.Provider>
  )
}

export const useProductTour = () => useContext(ProductTourContext)

export const ReactJoyrideNoSSR = dynamic(() => import('react-joyride'), {
  ssr: false,
})
