import { Button, Col, Image, Row, Space, Typography } from 'antd'
import { useAuth } from 'components/auth'
import { useState } from 'react'
import ApiCall from 'services/ApiCall'
import { trackEvent } from 'utils/analytics'

export const FormProviderCalendarManagment = ({ completed, checkStatus }) => {
  const [showCheckStatus, setShowCheckStatus] = useState(false)
  const { authUser } = useAuth()

  const handleConnectLinear = async () => {
    trackEvent(
      'User initiated authentication with Calendar',
      { provider: 'Google' },
      authUser.uuid,
    )
    ApiCall.GoogleCalendar.authorize().then((data) => {
      const { redirect_url } = data.data
      window.open(redirect_url)
      setShowCheckStatus(true)
    })
  }

  return (
    <Row className="max-w-lg mx-auto py-12" gutter={[16, 24]}>
      <Col span={24} className="onboarding-calendar-connection">
        <Typography variant="h3" component="h3">
          Integrate your Calender
        </Typography>
        <Typography>
          Link your calendar with Traqqie to align your schedule and WBSO hours.
          This allows you to effortlessly identify and add WBSO-eligible
          meetings, maintaining a cohesive and efficient tracking process.
        </Typography>
      </Col>
      <Col span={24}>
        <Button
          className="bg-gray-100 text-gray-700 border-none text-sm"
          block
          size="large"
          onClick={handleConnectLinear}
        >
          <Space direction="horizontal" align="center">
            <Image
              preview={false}
              style={{ width: '32px' }}
              className="inline-block"
              src="/thirdParties/google_g.png"
            />
            Connect your Google Calendar {completed ? ' (Connected)' : ''}{' '}
          </Space>
        </Button>
      </Col>
      {showCheckStatus ? (
        <>
          <Col span={24} className="mt-4 -mb-4">
            <Typography>
              Connected successfully with your calendar? Check the status and
              move on!
            </Typography>
          </Col>
          <Col span={24}>
            <Button
              type=""
              onClick={checkStatus}
              disabled={completed}
              className="w-full"
            >
              Check status
            </Button>
          </Col>
        </>
      ) : (
        ''
      )}
    </Row>
  )
}
