import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

import { useAuth } from 'components'
import { omitBy, sortBy } from 'lodash'
import moment from 'moment'
import { formatTimeLogData } from 'utils/helpers'

function useAdminTimeLog(props) {
  const { authUser } = useAuth()
  const options = props?.options || {}

  // const filterRange = props?.filterRange || []
  const startDate = props?.start.format('YYYY-MM-DD') || null
  const endDate = props?.end.format('YYYY-MM-DD') || null
  const queryKey = ['user-time-log', startDate, endDate, props?.company]

  // Condition to check if the query should be enabled
  const isQueryEnabled = authUser?.is_advisor ? !!props?.company : true

  // if (authUser?.is_advisor && !props?.company) {
  //   message.error('Please select a company')
  //   return { data: [], isLoading: false, refetch: () => {} }
  // }

  const query = useQuery(
    queryKey,
    () => ApiCall.TimeLog.getListAll(startDate, endDate, props?.company),
    {
      ...options,
      enabled: isQueryEnabled,
    },
  )

  const queryData = query.data?.data

  let data = []
  queryData?.forEach((_data) => {
    const userIndex = data?.findIndex(
      (v) => v.userUuid === _data?.user_info?.uuid,
    )

    if (userIndex < 0) {
      data.push({
        userUuid: _data?.user_info?.uuid || null,
        userName: _data?.user_info?.display_name || '',
        totalDuration: _data?.duration || 0,
        timelog: [formatTimeLogData(omitBy(_data, 'user'))],
      })
    } else {
      const _d = moment
        .duration(data[userIndex]?.totalDuration || '00:00:00')
        .add(_data?.duration || '00:00:00')
      const h = Math.floor(_d.asHours())
      const m = Math.floor(_d.asMinutes()) % 60
      const s = Math.floor(_d.asSeconds()) % 60
      const duration = [h, m === 0 ? '00' : m, s === 0 ? '00' : s].join(':')

      data[userIndex] = {
        ...data[userIndex],
        totalDuration: duration,
        timelog: sortBy(
          [
            ...data[userIndex]?.timelog,
            formatTimeLogData(omitBy(_data, 'user')),
          ],
          'uuid',
        ),
      }
    }
  })

  return {
    ...query,
    data,
  }
}

export default useAdminTimeLog
