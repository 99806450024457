import { Table } from 'antd'

const MyTable = ({ columns, dataSource, onRow, ...props }) => {
  const modifiedColumns = columns.map((c) => {
    return {
      ...c,
      onHeaderCell: () => {
        return {
          className: 'bg-primary text-white text-center',
          ...c?.onHeaderCell?.(),
        }
      },
      // onCell: { onCell },
    }
  })
  return (
    <Table
      rowKey="uuid"
      columns={modifiedColumns}
      dataSource={dataSource}
      onRow={onRow}
      className="w-100"
      scroll={{ x: '100%', ...props?.scroll }}
      {...props}
    />
  )
}

export default MyTable
