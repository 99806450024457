import { createContext, useContext, useState } from 'react'

// Create a context
const AdvisorSelectedCompanyContext = createContext()

// Create a custom hook for easier consumption of the context
export const useAdvisorSelectedCompany = () =>
  useContext(AdvisorSelectedCompanyContext)

// Create a provider component
export const AdvisorSelectedCompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(null)

  // Value to be passed to consuming components
  const value = { selectedCompany, setSelectedCompany }

  return (
    <AdvisorSelectedCompanyContext.Provider value={value}>
      {children}
    </AdvisorSelectedCompanyContext.Provider>
  )
}
