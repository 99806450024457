import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useMyUser(props) {
  const options = props?.options || {}
  const queryKey = ['my-user-data']
  const query = useQuery(queryKey, () => ApiCall.Users.me(), {
    ...options,
    staleTime: 1000 * 60 * 5,
  })

  return {
    ...query,
    data: query?.data?.data,
  }
}

export default useMyUser
