const initRudderStack = () => {
  if (typeof window !== 'undefined' && window.rudderanalytics) {
    window.rudderanalytics.ready(() => {})
  } else {
    setTimeout(initRudderStack, 100)
  }
}

initRudderStack()

function splitName(fullName) {
  // Trim any extra whitespace and split the name into parts
  const parts = fullName.trim().split(/\s+/)

  // If there's only one part, return it as the first name, leave the last name empty
  if (parts.length === 1) {
    return {
      firstName: parts[0],
      lastName: '',
    }
  }

  // Extract the first name (the first part) and last name (all remaining parts)
  const firstName = parts[0]
  const lastName = parts.slice(1).join(' ')

  return {
    firstName,
    lastName,
  }
}

export const trackEvent = (event, eventProps, userId = null) => {
  if (typeof window !== 'undefined' && window.rudderanalytics) {
    window.rudderanalytics.track(event, { ...eventProps, userId })
  }
}

export const isBrowser = () => typeof window !== 'undefined'

export const trackPageView = () => {
  if (isBrowser() && window.rudderanalytics) {
    window.rudderanalytics.page()
  }
}

export const identifyUser = (platformUuid, name, email) => {
  if (isBrowser() && window.rudderanalytics) {
    window.rudderanalytics.identify(platformUuid, {
      name,
      email,
      user_uuid: platformUuid,
      ...splitName(name),
    })
  }
}

export const identifyAnonymousUser = (email, name) => {
  if (isBrowser() && window.rudderanalytics) {
    window.rudderanalytics.identify({ email, name })
  }
}
