import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useUserRelevantIssues(props = {}) {
  const { options } = props || {}
  const { start_date, end_date } = props.requestDates || {}

  const queryKey = ['user-relevant-issues', start_date, end_date]
  const query = useQuery({
    queryKey,
    queryFn: () => ApiCall.Users.getRelevantIssues(start_date, end_date),
    options: { enabled: options.enabled || true, ...options },
    // ...options,
  })

  return {
    ...query,
    data: query.data?.data,
  }
}

export default useUserRelevantIssues
