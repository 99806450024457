import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useProjectList(props) {
  const options = props?.options || {}

  const queryKey = ['project-list']
  const query = useQuery(
    queryKey,
    () => ApiCall.Projects.getList(props?.company?.value),
    {
      ...options,
    },
  )

  return {
    ...query,
    // TEMPORARY:
    data: query.data?.data,
  }
}
export default useProjectList
