import { Button, Col, Row, Space, Typography } from 'antd'
import { useAuth } from 'components/auth'
import AsanaIcon from 'components/customIcons/AsanaIcon'
import JiraIcon from 'components/customIcons/JiraIcon'
import LinearIcon from 'components/customIcons/LinearIcon'
import { useState } from 'react'
import ApiCall from 'services/ApiCall'
import { trackEvent } from 'utils/analytics'

export const FormProviderIssueManagment = ({ completed, checkStatus }) => {
  const [showCheckStatus, setShowCheckStatus] = useState(false)
  const { authUser } = useAuth()

  const handleConnectEvent = async (provider) => {
    trackEvent(
      'User initiated authentication with Project Managment',
      { provider },
      authUser.uuid,
    )
  }

  const handleConnectAsana = async () => {
    handleConnectEvent('Asana')
    ApiCall.Asana.authorize().then((data) => {
      const { redirect_url } = data.data
      window.open(redirect_url)
      setShowCheckStatus(true)
    })
  }

  const handleConnectLinear = async () => {
    handleConnectEvent('Linear')
    ApiCall.Linear.authorize().then((data) => {
      const { redirect_url } = data.data
      window.open(redirect_url)
      setShowCheckStatus(true)
    })
  }

  const handleConnectJira = async () => {
    handleConnectEvent('Jira')
    ApiCall.Jira.authorize().then((data) => {
      const { redirect_url } = data.data
      window.open(redirect_url)
      setShowCheckStatus(true)
    })
  }

  return (
    <Row
      className="max-w-lg mx-auto py-12 onboarding-issue-mgmt"
      gutter={[16, 24]}
    >
      <Col span={24}>
        <Typography variant="h3" component="h3">
          Link to Your Issue Management System:
        </Typography>

        <Typography>
          Easily track your work by connecting Traqqie with your issue
          management provider. Just click on the icon of your provider and
          follow the simple steps to integrate. This connection is crucial for
          accurately logging the work on your tickets.
        </Typography>
      </Col>
      <Col span={20}>
        <Space size={4}>
          <LinearIcon
            width="70%"
            styles={{ textAlign: 'center' }}
            onClick={handleConnectLinear}
            className="clickable-icon"
          />
          <AsanaIcon
            width="70%"
            styles={{ textAlign: 'center' }}
            onClick={handleConnectAsana}
            className="clickable-icon"
          />
          <JiraIcon
            width="500%"
            styles={{ textAlign: 'center' }}
            onClick={handleConnectJira}
            className="clickable-icon"
          />
        </Space>

        {/* </Button> */}
      </Col>
      {showCheckStatus ? (
        <>
          <Col span={24} className="mt-4 -mb-4">
            <Typography>
              Connected successfully ? Check the status and move on!
            </Typography>
          </Col>

          <Col span={24}>
            <Button
              type=""
              onClick={checkStatus}
              disabled={completed}
              className="w-full"
            >
              Check status
            </Button>
          </Col>
        </>
      ) : (
        ''
      )}
    </Row>
  )
}
