import { useQuery } from 'react-query'

import ApiCall from 'services/ApiCall'

function useUsers(props) {
  const options = props?.options || {}
  const queryKey = ['users-list']
  const query = useQuery(queryKey, () => ApiCall.Users.getList(), {
    ...options,
  })

  return {
    ...query,
    data: query.data?.data,
  }
}

export default useUsers
