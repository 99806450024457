import { Result } from 'antd'

export const FinalState = ({ isCompleted }) => {
  return isCompleted ? (
    <Result
      status="success"
      title="You are ready to go!"
      subTitle="Let's get started"
    />
  ) : (
    <Result
      status="error"
      title="Some error occurred"
      subTitle="Please make sure you have completed all previous step! If this error persists, contact us."
    />
  )
}
