import { Steps } from 'antd'
import ConfirmationButton from 'components/ConfirmationButton'
import StyledModal from 'components/StyledModal'
import { useEffect, useState } from 'react'
import { FinalState } from './FinalState'
import { FormCompany } from './FormCompany'
import { FormProviderCalendarManagment } from './FormProviderCalendarManagment'
import { FormProviderIssueManagment } from './FormProviderIssueManagment'
import { WelcomeScreen } from './WelcomeScreen'

const ModalOnboarding = ({
  open,
  refetchUser,
  user,
  onFinished,
  onClose,
  ...resProps
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [isDomainCompleted, setIsDomainCompleted] = useState(false)
  const [isProviderCompleted, setIsProviderCompleted] = useState(false)
  const [isCalendarConnected, setIsCalendarConnected] = useState(false)
  const [showNextButton, setShowNextButton] = useState(true)

  useEffect(() => {
    if (user?.company) {
      setIsDomainCompleted(true)
      setCurrentStep(2)
    }
    if (user?.issue_management_provider?.length > 0) {
      setIsProviderCompleted(true)
      setCurrentStep(3)
    }
    if (isProviderCompleted && user?.calendar_provider?.length > 0) {
      setIsCalendarConnected(true)
      setCurrentStep(4)
    }
  }, [open, user, currentStep, refetchUser])

  const stepComponents = [
    { component: WelcomeScreen, onCompleted: null },
    { component: FormCompany, onCompleted: setIsDomainCompleted },
    {
      component: FormProviderIssueManagment,
      onCompleted: setIsProviderCompleted,
    },
    {
      component: FormProviderCalendarManagment,
      onCompleted: setIsCalendarConnected,
    },
    { component: FinalState, onCompleted: null },
  ]

  const renderStepComponent = () => {
    const Component = stepComponents[currentStep]
    if (!Component?.component) return null

    if (Component.component === FinalState) {
      return <Component.component isCompleted={true} />
    } else {
      return (
        <Component.component
          handleSubmit={null}
          loading={isLoading}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onCompleted={Component.onCompleted}
          onScheduled={() => {
            onFinished()
            refetchUser()
          }}
        />
      )
    }
  }

  return (
    <StyledModal
      title="Onboarding - Traqqie.com"
      open={open}
      footer={null}
      destroyOnClose
      width="800px"
      onCancel={onClose}
      closable
      {...resProps}
    >
      <Steps current={currentStep} direction="horizontal">
        {stepComponents.map((_, index) => (
          <Steps.Step key={index} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: '24px' }}>
        {renderStepComponent()}
      </div>
      <div
        className="steps-action"
        style={{ marginTop: '24px', textAlign: 'right' }}
      >
        {showNextButton && currentStep < stepComponents.length - 1 && (
          <ConfirmationButton
            type="primary"
            onClick={() => {
              setCurrentStep(currentStep + 1)
            }}
          >
            Next
          </ConfirmationButton>
        )}
        {currentStep === stepComponents.length - 1 && (
          <ConfirmationButton type="primary" onClick={onFinished}>
            Done
          </ConfirmationButton>
        )}
        {currentStep > 0 && (
          <ConfirmationButton
            style={{ margin: '0 8px' }}
            onClick={() => setCurrentStep(currentStep - 1)}
          >
            Previous
          </ConfirmationButton>
        )}
      </div>
    </StyledModal>
  )
}

export default ModalOnboarding
