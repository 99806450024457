import {
  CalendarOutlined,
  ClockCircleOutlined,
  FormOutlined,
  LogoutOutlined,
  ProjectOutlined,
  RiseOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import { PAGE } from './pages'

export const ADMIN_MENUS = {
  key: 'admin',
  label: 'Admin',
  icon: <TeamOutlined />,
  children: [
    {
      key: PAGE.adminTimeLog,
      label: 'Time Log Overview',
      icon: <ClockCircleOutlined />,
      application: false,
      adminproduct: true,
    },
    {
      key: PAGE.projectManagement,
      label: 'Project Management',
      icon: <ProjectOutlined />,
      application: false,
      adminproduct: true,
    },
    {
      key: PAGE.userManagement,
      label: 'User Management',
      icon: <UserAddOutlined />,
      application: true,
      adminproduct: true,
    },
    // {
    //   key: PAGE.advisorAccess,
    //   label: 'Advisor Access',
    //   icon: <SettingOutlined />,

    // },
  ],
}

export const MENUS = [
  {
    key: PAGE.dashboard,
    label: 'Dashboard',
    icon: <RiseOutlined />,
    application: false,
    adminproduct: true,
  },
  {
    key: PAGE.wbsoAppDashboard,
    label: 'WBSO Applications',
    icon: <FormOutlined />,
    application: true,
    adminproduct: false,
    companyadmin: true,
  },
  {
    key: PAGE.calendarView,
    label: 'Calendar View',
    icon: <CalendarOutlined />,
    application: false,
    adminproduct: true,
    companyadmin: false,
  },
  {
    key: PAGE.timeRegistration,
    label: 'Time Registration',
    icon: <CalendarOutlined />,
    application: false,
    adminproduct: true,
    companyadmin: false,
  },
  {
    key: PAGE.userTimeLog,
    label: 'Time Log Overview',
    icon: <ClockCircleOutlined />,
    application: false,
    adminproduct: true,
    companyadmin: false,
  },
  {
    key: 'logout',
    label: 'Logout',
    icon: <LogoutOutlined />,
    application: true,
    adminproduct: true,
  },
]

// export const ADVISOR_MENUS = [
//   {
//     key: PAGE.adminTimeLog,
//     label: 'Time Log Overview',
//     icon: <ClockCircleOutlined />,
//   },
//   {
//     key: PAGE.projectManagement,
//     label: 'Project Management',
//     icon: <ProjectOutlined />,
//   },
//   {
//     key: PAGE.userManagement,
//     label: 'User Management',
//     icon: <UserAddOutlined />,
//   },
//   {
//     key: 'logout',
//     label: 'Logout',
//     icon: <LogoutOutlined />,
//   },
// ]

export const localStorageKey = 'userinformation'

export const MESSAGE = {
  ERROR_SUBMIT: 'Failed to Submit Data',
  SUCCESS_SUBMIT: 'Successfully submitted data',
  UPDATE_SUBMIT: 'Updated',
}
